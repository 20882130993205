export { A as AlertController } from './alert.controller-59f0807e.js';
export { T as ToastController } from './toast.controller-b845ad35.js';
import './sanitize-html.util-2ab8a2fb.js';
class LoadingController {
  constructor() {
    this.LOADING_PORTAL_ID = '_CDS_LOADING_PORTAL_ID';
    this.loadingPortalElement = document.querySelector(`#${this.LOADING_PORTAL_ID}`);
    if (!this.loadingPortalElement) {
      const body = document.querySelector('body');
      this.loadingPortalElement = document.createElement('div');
      this.loadingPortalElement.setAttribute('id', this.LOADING_PORTAL_ID);
      body.append(this.loadingPortalElement);
    }
  }
  show(itemConfig) {
    const item = this.createItem(itemConfig);
    this.loadingPortalElement.appendChild(item);
    return item;
  }
  createItem(itemConfig) {
    const item = document.createElement('cds-loading');
    const loadingConfig = Object.assign(this.getDefaultConfig(), itemConfig);
    item.setAttribute('type', loadingConfig.type);
    item.setAttribute('prevent-close', loadingConfig.preventClose.toString());
    if (loadingConfig.message) {
      item.setAttribute('message', loadingConfig.message);
    }
    return item;
  }
  getDefaultConfig() {
    return {
      type: 'cargamos',
      preventClose: true
    };
  }
}
export { LoadingController };